import axios from "axios";
import { QuestionFavoriteRanking } from "@/types/question_favorite_ranking";

export class FavoriteRankingRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor(private projectId: number) {
    this.url = FavoriteRankingRepository.DOMAIN + "v1/projects/" + this.projectId + "/favorite_ranking/";
  }
  async getReport(): Promise<QuestionFavoriteRanking[]> {
    return axios.get(this.url).then((response) => {
      return response.data.questions;
    });
  }
}